<template>
    <div>
        <div>
            <van-cell-group title="新增">
                <van-field v-model="cat.text" is-link readonly label="巡检类型" @click="cat.show = true" />
                <!-- <van-field v-model="model.LOC_NAME" is-link readonly label="巡检位置" @click="loc.show = true" /> -->
                <van-field v-model="model.LOC_NAME" is-link label="巡检位置" />


                <van-field v-model="model.SAFE_REC_TIME" is-link readonly label="巡检时间" @click="time.show = true" />
                <van-field v-model="model.SUB_NAME" is-link readonly label="巡检人员" />

                <van-field v-model="model.DESCRIPTION" type="textarea" rows="3" autosize show-word-limit maxlength="100"
                    label="巡检内容" placeholder="请输入相关问题的详细说明" />

            </van-cell-group>

            <van-cell-group title="异常照片">
                <van-uploader :after-read="afterRead" v-model="picList" @delete="model.PIC_B64 = ''" max-count="1"
                    style="margin: 10px 0px 0px 10px" />
            </van-cell-group>

            <div style="margin: 15px">
                <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
                    loading-text="正在提交。。。">确认提交</van-button>
                <br />
                <van-button plain type="default" size="large" icon="cross" block to="/safe/abnormal">取消返回</van-button>
            </div>
        </div>

        <div>
            <van-popup v-model="cat.show" round position="bottom">
                <van-picker show-toolbar :columns="cat.ls" value-key="Safe_Class_Name" @cancel="screenCancel"
                    @confirm="screenConfirm" />
            </van-popup>
            <!-- <van-popup v-model="loc.show" round position="bottom">
                <van-cascader v-model="model.LOC_ID" :options="loc.ls" title="请选择分类" @close="loc.show = false"
                    @finish="onLocFinished" :field-names="{ text: 'NAME', value: 'ID', children: 'CHILDREN' }" />
            </van-popup> -->

            <van-popup v-model="time.show" round position="bottom">
                <van-datetime-picker type="datetime" :min-date="time.minDate" @cancel="time.show = false"
                    @confirm="timeConfirm" />
            </van-popup>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
export default {
    data() {
        return {
            model: {
                SUB_NAME: this.whale.user.get().NAME,
                PIC_B64: ''
            },
            picList: [],
            cat: {
                show: false,
                ls: [],
                text: ''
            },
            loc: {
                show: false,
                ls: []
            },
            time: { show: false, minDate: this.$moment().add(-1, 'y').toDate(), },
            ret: {
                loading: false
            }
        }
    },
    created() {
        this.getCat();
        // this.getLoc();
    },
    methods: {
        getCat() {
            let self = this;
            this.whale.remote.getCollection({
                url: '/api/Mobile/SAFE/SafeClassConfig/GetPageList',
                completed(m) {
                    console.log(m.ITEMS)
                    self.cat.ls = m.ITEMS
                }
            })
        },
        screenCancel() {
            this.cat.show = false
        },
        screenConfirm(val) {
            this.cat.show = false
            this.cat.text = val.Safe_Class_Name
            this.model.SAFE_CLASS_ID = val.ID
            this.model.SAFE_CLASS_NAME = val.Safe_Class_Name
            console.log(val)
        },
        // onCatFinished(sel) {
        //     this.cat.show = false;
        //     let n = sel.selectedOptions.length;
        //     if (n > 0) this.model.CAT_NAME = sel.selectedOptions[n - 1].FULL_NAME;
        // },
        // getLoc() {
        //     let self = this;
        //     this.whale.remote.getResult({
        //         url: "/api/Mobile/FIX/LocationApi/GetTree",
        //         completed: function (its) {
        //             self.loc.ls = its.DATA;
        //         }
        //     })
        // },
        // onLocFinished(sel) {
        //     this.loc.show = false;
        //     let n = sel.selectedOptions.length;
        //     if (n > 0) this.model.LOC_NAME = sel.selectedOptions[n - 1].FULL_NAME;
        // },

        timeConfirm(val) {
            this.time.show = false;
            this.model.SAFE_REC_TIME = this.$moment(val).format('YYYY-MM-DD hh:mm');
        },
        afterRead(f) {
            let self = this;
            this.whale.image.compress({
                file: f.file,
                callback(f) {
                    self.whale.image.toB64({
                        file: f,
                        callback(b64) { self.model.PIC_B64 = b64 }
                    })
                }
            })
        },
        submit() {
            let self = this;
            self.ret.loading = true;
            if (this.model.PIC_B64 == '') {
                Toast.fail('请上传异常图片')
                self.ret.loading = false
                return
            }
            this.whale.remote.getResult({
                url: "/api/Mobile/SAFE/SafeNoteApi/Save",
                data: this.model,
                finally() {
                    self.ret.loading = false;
                },
                completed() {
                    self.$dialog.confirm({
                        title: "完成",
                        message: "维修记录已成功提交，请等待处理！",
                        theme: "round",
                        confirmButtonText: "继续录入",
                        cancelButtonText: "返回"
                    }).then(() => {
                        self.$router.go(0);
                    }).catch(() => {
                        self.$router.go(-1)
                    })
                }
            })
        }
    }
}
</script>
<style scoped></style>